import Error from "@/common/api/Error";
import useWindowSize from "@/lib/hooks/useWindowSize";
import { SettingJson } from "@/types/Setting.type";
import { Moment } from "moment";
interface FilterParams {
	[key: string]: string | number | undefined | boolean;
}
class Helper {
	static convertedDateToTimeStamp = (myDate: Moment): number => {
		// console.log(myDate);
		const timestamp = myDate ? Math.round(myDate.valueOf() / 1000) : -1;
		return timestamp;
	};

	// static convertedTimestampToDate = (timestamp: number) => {
	//   return (
	//     new Date(timestamp * 1000).toLocaleTimeString("en-US", {
	//       hour: "numeric",
	//       minute: "numeric",
	//       hour12: true,
	//     }) +
	//     ", " +
	//     new Date(timestamp * 1000).toLocaleDateString("en-GB")
	//   );
	// };

	static convertedTimestampToDate = (timestamp: number, showtime?: boolean) => {
		const showTime = typeof showtime !== "undefined" ? showtime : true;
		const time = `${new Date(timestamp * 1000).toLocaleTimeString("en-US", {
			hour: "numeric",
			minute: "numeric",
			hour12: true,
		})} ,`;
		const date = new Date(timestamp * 1000).toLocaleDateString("en-GB");
		if (showTime) {
			return time + date;
		}
		return date;
	};

	static isMobile = () => {
		if (typeof window !== "undefined") {
			// Client-side-only code

			let widthDevice = window.innerWidth;
			const setWidthDevice = () => {
				widthDevice = window.innerWidth;
			};
			window.addEventListener("resize", setWidthDevice);
			if (Number(widthDevice) <= 768) {
				return true;
			} else {
				return false;
			}
		}
		return false;
	};

	static validatePhoneNumber(phone: string) {
		const regexPhoneNumber = /^[0]{1}[0-9]{9}$/;

		return phone.match(regexPhoneNumber) ? true : false;
	}
	static validateEmail(email: string) {
		const regex = /^[\w.-]+@([\w.-]+\.)+[a-z]{1,32}$/i;
		return email.match(regex) ? true : false;
	}
	static fileExtension(filename: string) {
		if (!filename) return "";
		var ext = (/[^./\\]*$/.exec(filename) || [""])[0];
		return ext.toLowerCase();
	}

	static moneyFormat(value: number): string {
		let output = "";

		//languagecode-countrycode:
		//languagecode: full list @ https://www.w3schools.com/tags/ref_language_codes.asp
		//countrycode: full list @ https://www.w3schools.com/tags/ref_country_codes.asp
		const locale = "vi-VN";

		//full currencylist at https://www.currency-iso.org/en/home/tables/table-a1.html
		const currency = "VND";

		const formatter = new Intl.NumberFormat(locale, {
			currency,
		});

		//formating data
		output = formatter.format(value).replace(/\./g, ",");

		return output;
	}

	static convertFilterToParams(
		filter: FilterParams,
		presix: string = "?"
	): string {
		if (Object.keys(filter).length <= 0) {
			return "";
		}
		const params = new URLSearchParams();
		for (const key in filter) {
			const value = filter[key];
			if (
				typeof value === "number" ||
				(typeof value === "string" && value.length > 0)
			) {
				params.append(key, value.toString());
			}
		}
		return presix + params.toString();
	}

	static convertParams(params: any): object {
		let newParam = { ...params };
		for (const prop in newParam) {
			if (
				newParam[prop] === null ||
				newParam[prop] === undefined ||
				(typeof newParam[prop] === "number" && newParam[prop] < 0) ||
				(typeof newParam[prop] === "string" && newParam[prop].length <= 0)
			) {
				delete newParam[prop];
			}
		}
		return newParam;
	}

	static getDomainComment() {
		let domain = "https://cross.namefragrance.vn";
		try {
			const hostname = window.location.hostname;
			if (hostname) {
				if (hostname.includes("uat")) {
					domain = "https://uatcross.namefragrance.vn";
				} else if (hostname.includes("namperfume.net")) {
					domain = "https://cross.namefragrance.vn";
				}
			}
		} catch (error) {
		} finally {
			return domain;
		}
	}

	static isDebug() {
		return process.env.REACT_APP_IS_DEBUG === "debug" || false;
	}

	static consoleLog(log: any) {
		if (this.isDebug()) {
			typeof log === "function" && log();
		}
	}

	static changeTheme = (theme: "theme1" | "theme2" | "") => {
		document.querySelector("html")?.setAttribute("data-theme", theme);
	};

	static isServer() {
		return typeof window === "undefined";
	}

	static isDev() {
		return ["development" || "test"].includes(process.env.NODE_ENV);
	}

	static maskLastNChars(inputString: string, n: number) {
		if (inputString.length < n) {
			return inputString;
		}

		const maskedString = inputString.slice(0, -n) + "*".repeat(n);
		return maskedString;
	}

	static removeDuplicatesArrObject = <T>(objects: T[], key: keyof T): T[] => {
		const uniqueObjects: T[] = [];
		const encounteredIds: Set<T[keyof T]> = new Set();

		for (const obj of objects) {
			const valueCompare = obj[key];
			if (!encounteredIds.has(valueCompare)) {
				encounteredIds.add(valueCompare);
				uniqueObjects.push(obj);
			}
		}

		return uniqueObjects;
	};

	static getArrayErrorDetail = (err: Error, key: string) => {
		let errorProductIdInvalidArray: number[] = [];

		if (err.error_detail && err.error_detail.length > 0) {
			for (const errorDetailItem of err.error_detail) {
				if (key + "_detail" in errorDetailItem) {
					errorProductIdInvalidArray = errorDetailItem.error_product_id_invalid;
					break;
				}
			}
		}
		return errorProductIdInvalidArray;
	};

	static getDataErrorDetail = <D>(err: Error, key: string): D | null => {
		let dataError = null as D | null;

		if (err.error_detail && err.error_detail.length > 0) {
			for (const errorDetailItem of err.error_detail) {
				if (key in errorDetailItem) {
					dataError = errorDetailItem[key];
					break;
				}
			}
		}
		return dataError;
	};
	static getValueFromArrayNumber(arr: number[], type: "min" | "max") {
		if (type === "min") {
			return Math.min(...arr);
		} else {
			return Math.max(...arr);
		}
	}

	static checkRenderArray<T>(
		data: Array<T>,
		current: number,
		keydata: keyof T
	) {
		if (data.length > current) {
			if (current === 0) {
				return data[0][keydata];
			}
			return data[current][keydata];
		} else {
			return null;
		}
	}

	static readMoney(amount: number) {
		const numberWords = [
			"",
			"một",
			"hai",
			"ba",
			"bốn",
			"năm",
			"sáu",
			"bảy",
			"tám",
			"chín",
		];
		const unitWords = ["", "nghìn", "triệu", "tỷ"];

		function readThreeDigits(num: number) {
			let result = "";
			let hundred = Math.floor(num / 100);
			let ten = Math.floor((num % 100) / 10);
			let one = num % 10;

			if (hundred > 0) {
				result += numberWords[hundred] + " trăm ";
			}

			if (ten === 0 && one === 0 && hundred > 0) {
				// Không thêm 'linh' vào result nếu hundred > 0
			} else if (ten === 1 && one > 0) {
				result += "mười ";
			} else if (ten > 1) {
				result += numberWords[ten] + " mươi ";
			}

			if (ten !== 1) {
				result += numberWords[one];
			}

			return result.trim();
		}

		if (amount === 0) {
			return "không đồng";
		}

		let words = "";
		let unitIndex = 0;

		while (amount > 0) {
			let threeDigits = amount % 1000;
			if (threeDigits > 0) {
				words =
					readThreeDigits(threeDigits) +
					" " +
					unitWords[unitIndex] +
					" " +
					words;
			}
			amount = Math.floor(amount / 1000);
			unitIndex++;
		}

		return words.trim();
	}

	static getVariantName(fullname: string) {
		const fullName = fullname;

		const lastIndex = fullname.lastIndexOf("-");

		return fullName.slice(lastIndex + 1).trim();
	}

	static getSizeName(fullname: string) {
		const fullNames = fullname.split(" ");

		const lastIndex = fullname.lastIndexOf("-");

		return fullNames[fullNames.length - 1];
	}

	static handleFilterActive<T>(
		state: string | undefined,
		datas: T[],
		key: keyof T
	) {
		if (!state) return [];
		return datas.filter((data) => {
			let arrBrand = state.split(",");
			return arrBrand.includes(data[key] as string);
		});
	}

	static parserStringToNumberInt(value: string | undefined) {
		let res;
		if (value) {
			res = Number.parseInt(value);
		}
		return res;
	}

	static getDataKeyMultiSetting(
		data: SettingJson[],
		key: string
	): SettingJson | null {
		if (data.length <= 0 || key.length <= 0) {
			return null;
		}
		return data.find((i) => i.key === key) || null;
	}
	static removeCharAtString(str: string) {
		while (str.charAt(0) === ",") {
			str = str.substring(1);
		}

		// Loại bỏ ký tự "," ở cuối chuỗi
		while (str.charAt(str.length - 1) === ",") {
			str = str.substring(0, str.length - 1);
		}

		return str.trim();
	}

	static checkTimeServer(server: number, from: number, to: number) {
		const serverTime = server > 0 ? server / 1000 : 0;
		if (serverTime <= 0) {
			return true;
		}
		if (from <= 0 && to <= 0) {
			return true;
		} else {
			if (from > 0 && to > 0) {
				return from <= serverTime && to >= serverTime;
			} else {
				if ((from > 0 && from <= serverTime) || (to > 0 && serverTime <= to)) {
					return true;
				}
				return false;
			}
		}
	}

	static validateKey(str: string) {
		// Chuyển đổi chuỗi sang chữ thường
		str = str.toLowerCase();

		// Loại bỏ các dấu tiếng Việt
		str = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

		// Thay thế các ký tự đặc biệt bằng dấu gạch ngang
		str = str.replace(/[^a-z0-9\s-]/g, "_");

		// Thay thế khoảng trắng và dấu gạch ngang liên tiếp bằng một dấu gạch ngang
		str = str.trim().replace(/\s+/g, "-").replace(/-+/g, "_");

		return str;
	}

	static addDomainToSrc(htmlString: string, domain: string) {
		// Sử dụng regex để tìm và thêm domain vào các src
		const updatedHtmlString = htmlString.replace(
			/(<img\s+[^>]*src=")([^"]*)"/g,
			(match, p1, p2) => {
				// Thêm domain vào giá trị của src nếu nó không bắt đầu bằng "http" và chưa có domain
				if (!p2.startsWith("http") && !p2.startsWith(domain)) {
					const newSrc = `${domain + "/uploads/filecloud/"}${p2}`;
					return `${p1}${newSrc}"`;
				}
				return match; // Giữ nguyên nếu src đã bắt đầu bằng "http" hoặc đã có domain
			}
		);

		return updatedHtmlString;
	}

	static checkEven(number: number) {
		return number % 2 === 0;
	}

	static convertCurencyToNumber(value: string | number) {
		// if (typeof value === "number") return value;
		// const valueReplace = value.replace(/[^0-9]/g, "");
		// return Number(valueReplace.length ? valueReplace : "0");
		// if (typeof value === undefined) return 0;
		if (typeof value === "number") return value;
		const valueReplace = value.toString().replace(/[^0-9]/g, "");
		return valueReplace.length ? Number(valueReplace) : 0;
	}

	static removeJWTLocal() {
		if (!Helper.isServer()) {
			localStorage.removeItem("JWT");
		}
	}
}

export default Helper;
